import * as types from '../actionTypes'

const initialState = {
    modalDisplayed: false,
    modalType: "",
    modalData: {}

}

function otherReducer(state = initialState, action) {
    switch (action.type) {
        case types.OPEN_MODAL:
            return { ...state, modalDisplayed: true, modalType: action.payload.modalType, modalData: action.payload.modalData }
        case types.CLOSE_MODAL:
            return { ...state, modalDisplayed: false, modalType: "", modalData: {} }
        default:
            return state
    }
}

export default otherReducer