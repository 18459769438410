import * as types from "../actionTypes";

export const closeModal = () => {
    console.log("Close Modal")
    return {
        type: types.CLOSE_MODAL
    }
}

export const openModal = (modalType, modalData = {}) => {
    console.log("Open Modal")
    return {
        type: types.OPEN_MODAL,
        payload: { modalType, modalData }
    }
}

