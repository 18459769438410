import React, { useEffect, useReducer, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import * as authActions from '../../redux/actions/authActions';
import * as cartActions from '../../redux/actions/cartActions';
import * as purchaseAction from '../../redux/actions/purchasedHistoryActions';
import * as subscribedAction from '../../redux/actions/subscribedCoursesActions';
import * as myCourseListingAction from '../../redux/actions/myCourseListingActions';
import * as userCourseSeatAction from '../../redux/actions/userCourseSeatActions';
import SEO from '../../components/SEO';
import { logoutTags } from '../../data/SEO/page-tags';

function Logout() {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(cartActions.removeAllFromCart());
        dispatch(authActions.logout());
        dispatch(purchaseAction.clearPurchaseState());
        dispatch(subscribedAction.clearSubscriptionState());
        dispatch(myCourseListingAction.clearMyCourseState());
        dispatch(userCourseSeatAction.clearUserCrsSeatState());
        history.push("/")
    }, [])

    return (
        <>
            <SEO specificToPage={logoutTags} />
        </>
    )
}

export default Logout
