import * as types from "../actionTypes";
import axios from "axios";
import APIConstants from "../../utils/APIConstants";
import partners from '../../data/partners.json';
import {CECourseRack} from '../../data/ceCourseRack.js'
import { generateCourseContent } from "../../data/courseContent";

export const fetchCoursesRequest = () => {
    return {
        type: types.FETCH_COURSES_REQUEST
    }
}

export const fetchCoursesSuccess = (courses, cecrsrack, suggestion) => {
    return {
        type: types.FETCH_COURSES_SUCCESS,
        payload: courses,
        cecrsrack : cecrsrack,
        suggestion : suggestion
    }
}

export const fetchAllShopifyCoursesSuccess = courses => {
    return {
        type: types.FETCH_ALL_SHOPIFY_COURSES_SUCCESS,
        payload: courses
    }
}

export const fetchCoursesFailure = () => {
    return {
        type: types.FETCH_COURSES_FAILURE
    }
}


let allShopifyCourses
let allRechargeCourses

export const fetchCourses = (partnerType = "shop") => {
    let partnerId = partners.filter(item => item.name === partnerType)[0].id;
    const shopifyReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.SHOPIFY_ALL_PRODUCTS_NEW + partnerId}`);
    const rechargeReq = axios.get(`${APIConstants.API_DESTINATION}${APIConstants.RECHARGE_ALL_PRODUCTS_NEW + partnerId}`);

    return (dispatch) => {
        dispatch(fetchCoursesRequest())
        axios.all([shopifyReq, rechargeReq])
            .then(res => {
                dispatch(fetchAllShopifyCoursesSuccess(res[0].data.data));
                allShopifyCourses = cleanUpShopifyResponse(res[0].data.data)
                allRechargeCourses = cleanUpRechargeResponse(res[1].data.data)
                const filteredCourses = {}
              
                let cecrsrack = CECourseRack().getCourseRack(allShopifyCourses,allRechargeCourses)
                
                dispatch(fetchCoursesSuccess(filteredCourses,cecrsrack, []));

                /** 
                * Bundle Course Content Generation 
                */
                generateCourseContent(cecrsrack);

                console.debug("=======crs rack ======>",cecrsrack);

            })
            .catch(err => {
                console.log(err)
                dispatch(fetchCoursesFailure())
            })
    }
}

// helpers
const cleanUpShopifyResponse = (allShopifyCourses) => {
    let tempObj = []
    allShopifyCourses.forEach(item => {
        deleteProps(item, ["created_at"])
        tempObj.push(item)
    })
    return allShopifyCourses
}

const cleanUpRechargeResponse = (allRechargeCourses) => {
    let tempObj = []
    allRechargeCourses.forEach(item => {
        deleteProps(item, ["collection_id", "discount_amount", "created_at", "discount_type"])
        tempObj.push(item)
    })
    return allRechargeCourses
}

function deleteProps(obj, prop) {
    for (const p of prop) {
        (p in obj) && (delete obj[p]);
    }
}

