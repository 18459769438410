import * as types from '../actionTypes';

const initialState = {
    courseList: [],
    loading: false,
    apiSuccess: undefined,
}

function userReducer(state = initialState, action) {

    switch (action.type) {
        case types.MYCOURSELIST_REQUEST:
            return { ...state, loading: true }
        case types.MYCOURSELIST_SUCCESS:
            return { courseList: action.courseList, loading: false, apiSuccess: true }
        case types.MYCOURSELIST_FAILURE:
            return { courseList: [], apiSuccess: false, loading: false }
        case types.CLEARMYCOURSESTATE:
            return { courseList: [], apiSuccess: undefined, loading: false }
        default:
            return state
    }
}


export default userReducer