import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import TagManager from 'react-gtm-module'
import Logout from "./pages/auth/Logout";

require( 'dotenv' ).config();

if ( process.env.NODE_ENV === 'production' ) {
  console.log = function () { };
}


/** partners */
const Home = lazy( () => import( "./components/Home" ) );
const Courses = lazy( () => import( "./components/Courses" ) );
const Cart = lazy(() => import("./components/Cart"));
const NotFound = lazy(() => import("./pages/NotFound"));

/** user */

const MyAccount = lazy(() => import("./pages/auth/MyAccount"));

// other pages
const OurPartners = lazy(() => import("./pages/OurPartners"));
const Policy = lazy(() => import("./pages/PrivacyPolicy"));
const Terms = lazy(() => import("./pages/TermsConditions"));
const FAQ = lazy(() => import("./pages/FAQ"));
const CustomerSupport = lazy(() => import("./pages/CustomerSupport"));
const ContactUs = lazy(() => import("./pages/ContactUs"));

const CourseDetail = lazy( () => import( "./components/CourseDetail" ) );
const CourseBundleDetail = lazy( () => import( "./components/CourseBundleDetail" ) );




let GTM_TAG_ID = '';

switch ( window.PARTNER_TYPE ) {
  case "shop":
    GTM_TAG_ID = "GTM-KHDXVVL";
    break
  case "egadd":
    GTM_TAG_ID = "GTM-TPPJPCQ";
    break
  case "techuk":
    GTM_TAG_ID = "GTM-K8XHZV3";
    break
  case "ioeit":
    GTM_TAG_ID = "GTM-5W8JV4R";
    break
  case "china":
    GTM_TAG_ID = "GTM-KR44CKP";
    break
  case "shop-landing":
    GTM_TAG_ID = "GTM-MNMHZF3";
    break
  case "livingston":
    GTM_TAG_ID = "GTM-5HNX9WP";
    break
  case "sgs":
    GTM_TAG_ID = "GTM-W2XX7J8";
    break
  case "zte":
    GTM_TAG_ID = "GTM-NV9T3PD";
    break
  case "abcd":
    GTM_TAG_ID = "GTM-NV9T3PD";
    break
}

console.log( "PARTNER_TYPE", window.PARTNER_TYPE );



const App = props => {


  useEffect( () => {
    TagManager.initialize( {
      gtmId: GTM_TAG_ID
    } )
  }, [] )

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path="/"
                  component={ Home }
                />

                <Route
                  exact
                  path="/home"
                  component={ Home }
                />
                <Route
                  exact
                  path={ process.env.PUBLIC_URL + "/courses/:param?" }
                  component={ Courses }
                />
                
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />

              <Route
                  exact
                  path={process.env.PUBLIC_URL + "/terms-conditions"}
                  component={Terms}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/privacy-policy"}
                  component={Policy}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/faq"}
                  component={FAQ}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/customer-support"}
                  component={CustomerSupport}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/contact-us"}
                  component={ContactUs}
                />

              <Route
                  exact
                  path={process.env.PUBLIC_URL + "/our-partners"}
                  component={OurPartners}
                />

              <Route
                  exact
                  path={process.env.PUBLIC_URL+"/course-details/:param1?/:param2?"}
                  component={CourseDetail}
              />

              <Route
                  exact
                  path={process.env.PUBLIC_URL+"/course-bundle-details/:param1?/:param2?"}
                  component={CourseBundleDetail}
              />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />

              <Route
                  exact
                  path={process.env.PUBLIC_URL + "/logout"}
                  component={Logout}
                />


              <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
              />
              <Redirect to="/not-found" />

              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

export default App;

