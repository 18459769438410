import * as types from "../actionTypes";
import Client from 'shopify-buy';
import { SHOPIFY_STOREFRONT_ACCESS_TOKEN, SHOPIFY_DOMAIN } from '../../config';
import * as otherActions from '../../redux/actions/otherActions';
import cryptojs from "crypto-js";

const client = Client.buildClient({
    storefrontAccessToken: SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    domain: SHOPIFY_DOMAIN
}, fetch);

//add to cart
export const addToCart = (handle,productId, variantId, chargeIntervalFrequency, orderIntervalFrequency, orderIntervalUnit, partnerType, addToast, displayToast = true,showModal,sku) => {
    return (dispatch) => {
        client.product.fetchByHandle(handle).then((product) => {
            // console.log("product", product)
            if (displayToast) {
                addToast("Course Added to cart", {
                    appearance: "success",
                    autoDismiss: true
                });
            }
            console.debug("======= showModal ==========",showModal)
            if(showModal){
                dispatch(otherActions.openModal("added-to-cart", { title: product.title, thumbnail: product.variants[0].image.src, handle: product.handle }));
            }

            dispatch({ type: types.ADD_TO_CART, payload: { handle: product.handle, productId, shopifyVariantId: variantId, chargeIntervalFrequency, orderIntervalFrequency: parseInt(orderIntervalFrequency[0]), orderIntervalUnit, partnerType, variantId: product.variants[0].id,sku:encryptSKU(sku)} });
        }).catch(e => console.log("error", e));
    }
}


const encryptSKU = (sku) =>{
    if(sku){
        const key ="ContentEnablers*#"
        const encryptedBase64 = cryptojs.AES.encrypt(sku, key).toString();
        return encryptedBase64
    }
}


//remove from cart
export const removeFromCart = (handleToRemove, addToast, displayToast = true) => {
    return dispatch => {
        if (addToast && displayToast) {
            addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
        }
        dispatch({ type: types.REMOVE_FROM_CART, payload: handleToRemove });
    };
};

//change course seats quantity from cart
export const changeQty = (handle, changeByNumber) => {
    return dispatch => {
        dispatch({ type: types.CHANGE_QTY, payload: { handle, changeByNumber } });
    };
};

//remove all from cart
export const removeAllFromCart = () => {
    return dispatch => {
        // if (addToast) {
        //     addToast("Removed All From Cart", {
        //         appearance: "error",
        //         autoDismiss: true
        //     });
        // }
        dispatch({ type: types.REMOVE_ALL_FROM_CART });
    };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
    if (item.stock) {
        return item.stock;
    } else {
        return item.variation
            .filter(single => single.color === color)[0]
            .size.filter(single => single.name === size)[0].stock;
    }
};

export const fetchCourseByHandle = (handle, addToast) => {
    return (dispatch) => {
        client.product.fetchByHandle(handle).then((product) => {
            dispatch(addToCart(product.variants[0].id, product.courseType, product.shopifyHandle, product.rechargeHandle, product.title, handle, product.variants[0].price, addToast))
        });
    }
}


export const updateItemIncart = (obj,line,op) => {
    return dispatch => {
        console.debug("obj",obj)
        dispatch({ type: types.UPDATE_ITEM_IN_CART, payload:{course:obj,line:line,op:op} });
    };
};


export const addToCartOnSwitch = (handle,productId, variantId, chargeIntervalFrequency, orderIntervalFrequency, orderIntervalUnit, partnerType) => {
    return (dispatch) => {
        client.product.fetchByHandle(handle).then((product) => {
            // dispatch(otherActions.openModal("added-to-cart", { title: product.title, thumbnail: product.variants[0].image.src, handle: product.handle }));
            dispatch({ type: types.ADD_TO_CART, payload: { 
                        handle: product.handle, 
                        productId, 
                        shopifyVariantId: variantId,
                        chargeIntervalFrequency, 
                        orderIntervalFrequency: parseInt(orderIntervalFrequency[0]), 
                        orderIntervalUnit, partnerType, 
                        variantId: product.variants[0].id
                    } 
                 });
        }).catch(e => console.log("error", e));
    }
}

export const removeFromCartOnSwitch = (handleToRemove, addToast, displayToast = true) => {
    return dispatch => {
        dispatch({ type: types.REMOVE_FROM_CART, payload: handleToRemove });
    };
};